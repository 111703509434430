<template>
  <SharedServiceCard v-bind="props" :item="props.item">
    <template #header>
      <SharedServiceCardHeader
        :classes="{
          ...props.classes?.header,
          root: [{ 'p-3': props.variant === 'small' }, props.classes?.header?.root],
        }"
        :loading
      >
        <template #icon>
          <IconRankBurger v-if="props.item?.id === 'snp500'" :size="variant === 'small' ? 10 : 12" />
          <IconGraph v-else :size="variant === 'small' ? 10 : 12" />
        </template>

        <template #title>
          <span
            :class="
              cn(
                'card-text-shadow font-medium leading-none',
                { 'text-xxs': variant === 'small' },
                props.classes?.header?.title,
                {}
              )
            "
            >Portfolio</span
          >
        </template>
      </SharedServiceCardHeader>
    </template>

    <!-- @ts-ignore -->
    <template #footer="slotProps">
      <slot v-bind="slotProps" name="footer">
        <div class="flex items-center gap-2 whitespace-pre">
          <UiSkeleton
            :classes="{
              slot: 'reverse md:gap-xxs prose:leading-none flex md:flex-row md:items-center',
              line: 'w-l',
            }"
            :loading="loading"
          >
            <span class="grid-cols block grid font-light text-white/70">
              {{ item?.assetCount }} {{ $t("common.stocks") }}
            </span>
          </UiSkeleton>

          <UiIconCircleSeparator class="mx-1" />

          <UiSkeleton
            :loading="loading"
            :classes="{
              slot: 'md:gap-xxs prose:leading-none flex flex-col-reverse md:flex-row md:items-center',
              line: 'w-l',
            }"
          >
            <UiPositiveNegative
              :classes="{
                span: 'font-light text-white/70',
                success: 'text-success-100',
              }"
              :value="item.accumulatedYield || 0"
              :format="(v) => toPercent(v, false, 2)"
            />
          </UiSkeleton>
        </div>

        <UiSkeleton :loading="loading" :classes="{ slot: 'ms-auto', line: 'ms-auto h-6 w-16 rounded-full' }">
          <UiButton
            class="ms-xxxs px-xs flex h-6 items-center justify-center rounded-full bg-black/40 font-medium backdrop-blur-sm"
            v-if="variant !== 'small' && item?.to"
            :to="item?.to"
            size="xs"
            rounded="full"
            :id="genClickId('stocks-portfolios', 'view', item.id)"
          >
            {{ $t("common.view") }}
          </UiButton>
        </UiSkeleton>
      </slot>
    </template>
  </SharedServiceCard>
</template>

<script setup lang="ts" generic="T extends StockServiceCardItem">
import type { ServiceCardProps } from "@finq/app-base/components/shared/ServiceCard/ServiceCard.vue"

import { StockServiceCardItem } from "@finq/stocks/composables/useStockIndexServices"

const props = withDefaults(defineProps<ServiceCardProps>(), { size: "255px" })
</script>
